import React from 'react';

export default function Videos (props) {
    return (
        <div className="page">
            <br></br>
            <div className='videos-header'>
                Videos
            </div>   
        </div>
    )
}

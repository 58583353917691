import React from 'react';
import '../styles/pages.css';

export default function Newsletter (props) {

    return (
        <div className="page">
            <br></br>
            <div className='newsletter-header'>
                Newsletter
            </div>   
        </div>
    )
}

import React from 'react';
import '../styles/pages.css';

export default function About () {

    return (
        <div className='page'>
            <br></br>
            <div className='about-header'>
                About
            </div> 
        </div>

    )
}
